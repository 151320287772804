import * as React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>No pyjamas found!</h1>
      <p>Sorry, the pyjamas you were looking for cannot be found.</p>
      <p>I might be wearing them.</p>
    </Layout>
  );
};

export default NotFoundPage;
